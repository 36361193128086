import React, { useEffect } from 'react'
import Layout from '../components/layout'
import  '../styles/pages/typ/_typ.scss'
import { TitleStroke } from '../components/_titles/_header_title_stroke'
import { ButtonSquare } from '../components/buttons/_button_square'

const Typ = (props) => {

    useEffect(()=>{
		document.querySelector('html').classList.add('html-typ')

		return () => {
			document.querySelector('html').classList.remove('html-typ')
		}
	},[])

    return(
        <Layout>
            <div className="c-thankyou">
                <div className="c-thankyou__container">   
                    <TitleStroke _className="visible-xs" textStroke="hemos recibido tu" text="información" colorStroke="#2977bc" colorText="#2977bc" />
                    <TitleStroke _className="visible-sm" textStroke="hemos recibido" text="tu información" colorStroke="#2977bc" colorText="#2977bc" />
                    <div className="c-thankyou__text">
                        <p>Uno de nuestros asesores se pondrá en contacto contigo en un plazo máximo de 24 horas.</p>
                    </div>
                    <div className="c-thankyou_button--container">
                        <ButtonSquare
                            _bg_color='#2977bc'
                            _text='Regresar'
                            _onClick={ () => window.history.back() }
                            _className={`c-thankyou_button`}
                        />
                        <ButtonSquare
                            _bg_color='#febe23'
                            _text='home'
                            _to={`/`}
                            _className={`c-thankyou_button`}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Typ
